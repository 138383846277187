// for language switcher
import enFlag from '../assets/images/en-flag.png';
import deFlag from '../assets/images/de-flag.png';
import zhFlag from '../assets/images/zh-flag.png';
import inFlag from '../assets/images/in-flag.png';
import esFlag from '../assets/images/es-flag.png';
import defaultBannerImage from '../assets/images/banner.png';

// Define the Language type
type Language = {
    code: string;
    name: string;
    nativeName: string;
    flag: string;
};

// Define the TrackingEvent type
type TrackingEvent = {
    stepId: string;
    message: string;
};

// Define API Call options
type ApiCallMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
type ApiCallBody = Record<string, unknown>;
type AuthResponse = {
    csrfToken: string;
    additionalParameters: {
        sessionId: string;
    };
    title: {
        bannerImages: string[];
    }
};

export const CONFIG = {
    // Setting up the language array here
    languages: [
        { code: 'es', name: 'Spanish', nativeName: 'Español', flag: esFlag },
        { code: 'en', name: 'English', nativeName: 'English', flag: enFlag },
        { code: 'de', name: 'German', nativeName: 'Deutsch', flag: deFlag },
        { code: 'hi', name: 'Hindi', nativeName: 'हिन्दी', flag: inFlag },
        { code: 'zh', name: 'Chinese', nativeName: '汉语 / 中文', flag: zhFlag },
    ] as Language[],

    // API setup here
    API_URL: process.env.REACT_APP_API_URL,
    ERROR_PAGE_URL: process.env.REACT_APP_ERROR_PAGE_URL,

    get CSRF_TOKEN(): string | null {
        return sessionStorage.getItem('csrfToken');
    },

    get SESSION_ID(): string | null {
        return sessionStorage.getItem('sessionId');
    },

    async fetchAndStoreSession(): Promise<AuthResponse | null> {
        try {
            const response = await fetch(`${this.API_URL}/api/v1/auth/start`, {
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok) {
                // Parse the response and store tokens only if status is 200
                const data = await response.json();
                sessionStorage.setItem('csrfToken', data.csrfToken);
                sessionStorage.setItem('sessionId', data.additionalParameters.sessionId);
                sessionStorage.setItem('clientId', data.additionalParameters.clientId);
                // Handle banner images
                const bannerImages = Array.isArray(data.title?.bannerImages) && data.title.bannerImages.length > 0
                    ? data.title.bannerImages
                    : [defaultBannerImage]; // Use default if no banner images

                sessionStorage.setItem('bannerImages', JSON.stringify(bannerImages));

                return {
                    ...data,
                    title: {
                        ...data.title,
                        bannerImages,
                    },
                };
            } else {
                console.error(`API call failed with status ${response.status}`);
                const errorResponse = await response.json();
                const requestId = errorResponse.requestId || 'unknown';
                // Redirect to error page
                window.location.href = `${this.ERROR_PAGE_URL}?requestId=${encodeURIComponent(requestId)}`;
                return null;
            }
        } catch (error) {
            console.error('Error fetching auth response', error);
            window.location.href = `${this.ERROR_PAGE_URL}`;
            throw error;
        }
    },

    async fetchBannerImages(): Promise<string> {
        const storedImages = sessionStorage.getItem('bannerImages');

        if (storedImages) {
            const bannerImages: string[] = JSON.parse(storedImages);
            return bannerImages[0]; // Return the first image
        }

        return defaultBannerImage; // Return default image if API fails or no banner images are available
    },

    async apiCall(endpoint: string, method: ApiCallMethod, body?: ApiCallBody): Promise<Response> {
        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.CSRF_TOKEN || '',
        };

        const options = {
            method,
            headers,
            credentials: 'include' as RequestCredentials,
            ...(body && { body: JSON.stringify(body) }),
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${this.API_URL}${endpoint}`, options);
        if (!response.ok) {
            throw {
                status: response.status,
                message: `API call to ${endpoint} failed with status ${response.status}`,
            };
        }

        return response;
    },

    async sendTrackingEvent({ stepId, message }: TrackingEvent): Promise<void> {
        const body = {
            timestamp: Date.now(),
            eventId: 7,
            sfId: "",
            clientId: sessionStorage.getItem('clientId'),
            sessionId: this.SESSION_ID || '',
            stepId,
            message,
        };

        try {
            const response = await fetch('https://sfid.trk.eco.stillfront.com', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error(`Tracking request failed with status ${response.status}`);
            }

            console.log('Tracking event sent:', body);
        } catch (error) {
            console.error('Failed to send tracking event:', error);
        }
    }
};
